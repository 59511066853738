const BitKeep = (props) => <svg {...props} version="1.0" xmlns="http://www.w3.org/2000/svg"
width="170.000000pt" height="170.000000pt" viewBox="0 0 170.000000 170.000000"
preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,170.000000) scale(0.100000,-0.100000)"
fill="#7524F9" stroke="none">
<path d="M0 850 l0 -850 850 0 850 0 0 850 0 850 -850 0 -850 0 0 -850z m1120
456 c129 -75 241 -144 248 -153 8 -11 12 -39 10 -73 l-3 -54 -97 -56 c-54 -30
-98 -57 -98 -60 0 -3 32 -23 72 -45 39 -22 84 -51 100 -63 28 -23 28 -25 28
-132 0 -99 -2 -111 -22 -133 -31 -34 -480 -287 -508 -287 -23 0 -179 85 -192
104 -4 6 -4 14 0 18 4 3 115 68 247 144 132 75 244 140 248 144 11 10 -189
124 -206 117 -6 -3 -126 -71 -266 -151 l-254 -146 -36 21 c-58 35 -71 55 -71
114 l0 53 365 209 c200 114 364 211 365 215 0 4 -45 32 -100 63 l-99 57 -255
-147 c-141 -81 -260 -144 -266 -140 -15 9 -13 207 3 228 6 9 122 80 257 157
185 107 251 140 270 136 14 -3 131 -66 260 -140z"/>
</g>
</svg>

export default BitKeep;