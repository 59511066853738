import { SVGProps } from "react";

const Keplr = (props: SVGProps<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none" {...props}>
    <g clip-path="url(#clip0_425_5107)">
        <path d="M32.4545 0H9.54545C4.27365 0 0 4.27365 0 9.54545V32.4545C0 37.7264 4.27365 42 9.54545 42H32.4545C37.7264 42 42 37.7264 42 32.4545V9.54545C42 4.27365 37.7264 0 32.4545 0Z" fill="url(#paint0_linear_425_5107)" />
        <path d="M32.4545 0H9.54545C4.27365 0 0 4.27365 0 9.54545V32.4545C0 37.7264 4.27365 42 9.54545 42H32.4545C37.7264 42 42 37.7264 42 32.4545V9.54545C42 4.27365 37.7264 0 32.4545 0Z" fill="url(#paint1_radial_425_5107)" />
        <path d="M32.4545 0H9.54545C4.27365 0 0 4.27365 0 9.54545V32.4545C0 37.7264 4.27365 42 9.54545 42H32.4545C37.7264 42 42 37.7264 42 32.4545V9.54545C42 4.27365 37.7264 0 32.4545 0Z" fill="url(#paint2_radial_425_5107)" />
        <path d="M32.4545 0H9.54545C4.27365 0 0 4.27365 0 9.54545V32.4545C0 37.7264 4.27365 42 9.54545 42H32.4545C37.7264 42 42 37.7264 42 32.4545V9.54545C42 4.27365 37.7264 0 32.4545 0Z" fill="url(#paint3_radial_425_5107)" />
        <path d="M17.2526 32.2614V22.5192L26.7185 32.2614H31.9849V32.0079L21.0964 20.9122L31.1469 10.3857V10.2614H25.8464L17.2526 19.5635V10.2614H12.9849V32.2614H17.2526Z" fill="white" />
    </g>
    <defs>
        <linearGradient id="paint0_linear_425_5107" x1="21" y1="0" x2="21" y2="42" gradientUnits="userSpaceOnUse">
            <stop stop-color="#1FD1FF" />
            <stop offset="1" stop-color="#1BB8FF" />
        </linearGradient>
        <radialGradient id="paint1_radial_425_5107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.00623 40.4086) rotate(-45.1556) scale(67.3547 68.3624)">
            <stop stop-color="#232DE3" />
            <stop offset="1" stop-color="#232DE3" stop-opacity="0" />
        </radialGradient>
        <radialGradient id="paint2_radial_425_5107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(39.7379 41.7602) rotate(-138.45) scale(42.1137 64.2116)">
            <stop stop-color="#8B4DFF" />
            <stop offset="1" stop-color="#8B4DFF" stop-opacity="0" />
        </radialGradient>
        <radialGradient id="paint3_radial_425_5107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.6501 0.311498) rotate(90) scale(33.1135 80.3423)">
            <stop stop-color="#24D5FF" />
            <stop offset="1" stop-color="#1BB8FF" stop-opacity="0" />
        </radialGradient>
        <clipPath id="clip0_425_5107">
            <rect width="42" height="42" fill="white" />
        </clipPath>
    </defs>
</svg>

export default Keplr