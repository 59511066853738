const ArgentX = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <rect width="32" height="32" rx="8" fill="black" />
            <path d="M18.4018 7.55556H13.5982C13.4377 7.55556 13.3091 7.68747 13.3056 7.85143C13.2085 12.4603 10.8484 16.8347 6.78608 19.9331C6.65711 20.0314 6.62773 20.2162 6.72202 20.3493L9.53253 24.3196C9.62815 24.4548 9.81444 24.4853 9.94558 24.386C12.4856 22.4613 14.5287 20.1395 16 17.566C17.4713 20.1395 19.5145 22.4613 22.0545 24.386C22.1856 24.4853 22.3719 24.4548 22.4676 24.3196L25.2781 20.3493C25.3723 20.2162 25.3429 20.0314 25.214 19.9331C21.1516 16.8347 18.7915 12.4603 18.6946 7.85143C18.6911 7.68747 18.5623 7.55556 18.4018 7.55556Z" fill="white" />
            <path d="M24.7236 10.492L24.2231 8.92439C24.1213 8.60614 23.8734 8.35824 23.5577 8.26023L22.0039 7.77595C21.7895 7.70906 21.7873 7.40177 22.0011 7.33201L23.5469 6.82466C23.8609 6.72146 24.106 6.46952 24.2027 6.15011L24.6798 4.57502C24.7458 4.35709 25.0489 4.35477 25.1183 4.57156L25.6188 6.13915C25.7206 6.4574 25.9686 6.70531 26.2842 6.8039L27.838 7.28761C28.0524 7.3545 28.0547 7.66179 27.8408 7.73213L26.295 8.23948C25.9811 8.3421 25.736 8.59404 25.6393 8.91402L25.1621 10.4885C25.0961 10.7065 24.793 10.7088 24.7236 10.492Z" fill="white" />
        </svg>
    )
}

export default ArgentX